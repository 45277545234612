import React from 'react';
import Grid from '@material-ui/core/Grid'
import { useStaticQuery, graphql } from "gatsby"

import GamesLayout from '../../components/GamesLayout';

import mp from './qr.jpg';

import SEO from '../../components/SEO';

const useSEO = () => {
  const { contentfulComponentSeo, site } = useStaticQuery(
    graphql`
    query MPqr {
      contentfulComponentSeo(name: {eq: "mpqr"}, node_locale: {eq: "es-AR"}) {
        title
        description {
          description
        }
        image {
          gatsbyImageData(layout:CONSTRAINED)
          id
          title
        }
        name
      }
      site {
        siteMetadata {
          siteUrl
        }
      }

    }
    `
  )
  return { ...contentfulComponentSeo, siteUrl: site.siteMetadata.siteUrl }
}

const Page = (props) => {
  const { contentIds, ...context } = props.pageContext
  const contentfulComponentSeo = useSEO()

  return (
    <GamesLayout {...context}>
      <SEO {...contentfulComponentSeo}  {...props.pageContext} seo={{...contentfulComponentSeo}} pathName="/payment/mp" />

      <Grid container direction="row">
        <Grid item xl={12}>
          <img style={{margin: 'auto', width: '100%'}} src={mp} alt="Mercado Pago QR" />

        </Grid>

      </Grid>
    </GamesLayout>
  )
}

export default Page;
